import {
  CurrentUserService,
  Nullable,
  RouteConfiguration,
  RouteDataContext,
  RouteDataService,
  WixCodeApi,
} from '../../../../types';
import { SLUG_PLACEHOLDERS } from '../../../../constants';

export class RouteData implements RouteDataService {
  private routes: RouteConfiguration[] = [];
  private membersAreaPagePrefix: string = '';

  constructor(
    private readonly locationAPI: Pick<WixCodeApi['location'], 'path'>,
    private readonly currentUserService: CurrentUserService,
  ) {}

  initializeRouteData({ routes, membersAreaPagePrefix }: RouteDataContext) {
    this.routes = routes;
    this.membersAreaPagePrefix = membersAreaPagePrefix;
  }

  getRouteData() {
    const membersAreaPagePath = this.getMembersAreaPagePath();
    const route = this.getRouteByPath(membersAreaPagePath?.routePath ?? null);

    return {
      slugOrId: membersAreaPagePath?.slugOrId ?? null,
      stateId: route?.state ?? null,
      isStatePrivate: route?.private ?? false,
      visibleForRoles: route?.vfr ?? [],
      path: route?.path ?? '',
      visibleWidgetId: route?.widgetId!,
    };
  }

  private getRouteByPath(routePath: Nullable<string>) {
    return routePath ? this.findRouteByPath(routePath) : this.getDefaultRoute();
  }

  private findRouteByPath(statePath: Nullable<string>) {
    return this.routes.find(({ path }) => path === statePath) ?? null;
  }

  private getDefaultRoute(): RouteConfiguration | null {
    const [firstRoute] = this.routes;
    const homeRoute = this.routes.find(({ home }) => home);
    return homeRoute ?? firstRoute ?? null;
  }

  private getMembersAreaPagePath(): Nullable<{
    routePath: string;
    slugOrId: Nullable<string>;
  }> {
    const { path } = this.locationAPI;

    const indexOfMembersAreaPagePrefix = path.indexOf(
      this.membersAreaPagePrefix,
    );
    const membersAreaPagePath = path.slice(indexOfMembersAreaPagePrefix);
    const membersPathWithoutPrefix = membersAreaPagePath.slice(1);

    if (!membersAreaPagePath.length) {
      return null;
    }

    return {
      routePath: this.getRoutePath(membersPathWithoutPrefix),
      slugOrId: this.getSlugOrId(membersPathWithoutPrefix),
    };
  }

  private getSlugOrId(membersPathWithoutPrefix: string[]) {
    const pathContainsSlug = this.pathContainsSlug(membersPathWithoutPrefix);
    const slugOrId = membersPathWithoutPrefix[0];

    if (!pathContainsSlug || SLUG_PLACEHOLDERS.includes(slugOrId)) {
      return this.currentUserService.getCurrentUserId();
    }

    return slugOrId;
  }

  private getRoutePath(membersPathWithoutPrefix: string[]) {
    const pathContainsSlug = this.pathContainsSlug(membersPathWithoutPrefix);

    if (!pathContainsSlug) {
      return membersPathWithoutPrefix[0];
    }

    return membersPathWithoutPrefix[1];
  }

  private pathContainsSlug(membersPathWithoutPrefix: string[]) {
    const routePath = membersPathWithoutPrefix[1];

    return this.routes.some(({ path }) => path === routePath);
  }
}
