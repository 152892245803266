import {
  Bind,
  ContextProps,
  ContextServices,
  ControllerConfig,
  ControllerData,
  CreateControllerParams,
  FlowAPI,
  InitState,
  W,
} from '../../../types';
import { getState } from './state';
import { AnimationsService } from '../services/animations';
import { MultiStateBoxService } from '../services/multi-state-box';
import { ErrorStateService } from '../services/error-state';
import { NavigationStateService } from '../services/navigation-state';
import { InstanceService } from '../services/instance-service';
import { currentUserServiceFactory } from '../services/current-user';
import { MembersService } from '../services/members';
import { RolesService } from '../services/roles';
import { WidgetPluginHostService } from '../services/widget-plugin-host';
import { MenuService } from '../services/menu';
import { setContextForPublicAPI } from '../../../viewer';
import { PageService } from '../services/page';
import { RouteData } from '../services/route-data';
import { RoutesConfigurationServiceViewer } from '../services/routes-configuration';
import { MembersAreaPublicAPI } from '../services/membes-area-public-api';
import { MonitoringService } from '../services/monitoring';

type CreateContextProps = {
  $w: W;
  controllerConfig: ControllerConfig;
  flowAPI: FlowAPI;
  $bind: Bind;
  initState: InitState;
  contextServices: ContextServices;
};

type CreateContextServicesProps = Pick<
  CreateContextProps,
  '$w' | 'flowAPI' | 'controllerConfig' | '$bind'
>;

const createContextProps = ({
  controllerConfig,
  flowAPI,
  $bind,
  initState,
  contextServices,
  $w,
}: CreateContextProps): ContextProps => {
  const { wixCodeApi } = controllerConfig;
  const config = controllerConfig.config as unknown as ControllerData;
  const state = getState({
    initState,
    contextServices,
    config,
  });

  return {
    $w,
    bind: $bind,
    flowAPI,
    wixCodeApi,
    state,
  };
};

const createContextServices = ({
  $w,
  flowAPI,
  controllerConfig: { appParams, wixCodeApi },
}: CreateContextServicesProps): ContextServices => {
  const membersAreaPublicAPI = new MembersAreaPublicAPI(
    wixCodeApi.site.getPublicAPI,
  );
  const animationsService = new AnimationsService($w);
  const multiStateBoxService = new MultiStateBoxService($w);
  const errorStateService = new ErrorStateService(
    $w,
    membersAreaPublicAPI,
    flowAPI.translations.t,
  );
  const navigationStateService = new NavigationStateService();
  const instanceService = new InstanceService(appParams, wixCodeApi.site);
  const currentUserService = currentUserServiceFactory(
    flowAPI,
    instanceService,
  );
  const membersService = new MembersService(flowAPI.httpClient);
  const rolesService = new RolesService(flowAPI.httpClient);
  const routeDataService = new RouteData(
    wixCodeApi.location,
    currentUserService,
  );
  const widgetPluginHostService = new WidgetPluginHostService(
    $w,
    routeDataService,
  );
  const menuService = new MenuService({
    $w,
    routeDataService,
  });
  const pageService = new PageService(wixCodeApi.site);
  const routesConfigurationService = new RoutesConfigurationServiceViewer(
    flowAPI.httpClient,
  );
  const monitoringService = new MonitoringService(
    flowAPI.fedops,
    flowAPI.errorMonitor,
    flowAPI.environment,
    flowAPI.controllerConfig,
  );

  return {
    membersAreaPublicAPI,
    animationsService,
    multiStateBoxService,
    errorStateService,
    navigationStateService,
    rolesService,
    instanceService,
    currentUserService,
    membersService,
    routeDataService,
    widgetPluginHostService,
    menuService,
    pageService,
    routesConfigurationService,
    flowAPI,
    monitoringService,
  };
};

export const getContext = ({
  $w,
  controllerConfig,
  flowAPI,
  $bind,
  initState,
}: CreateControllerParams) => {
  const contextServices = createContextServices({
    $w,
    controllerConfig,
    flowAPI,
    $bind,
  });

  const contextProps = createContextProps({
    $w,
    controllerConfig,
    flowAPI,
    $bind,
    initState,
    contextServices,
  });

  setContextForPublicAPI({
    state: contextProps.state,
    menuService: contextServices.menuService,
    multiStateBoxService: contextServices.multiStateBoxService,
    errorStateService: contextServices.errorStateService,
  });

  return { contextProps, contextServices };
};
