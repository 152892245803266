import { makeAutoObservable } from 'mobx';

import {
  ContextProps,
  MenuContext,
  MenuItem,
  MenuService as IMenuService,
  RouteConfiguration,
  RouteDataService,
  W,
} from '../../../types';
import { SLUG_PLACEHOLDERS, ViewerMenuId } from '../../../constants';
import { getIsTabAccessibleWithUserRoles } from '../utils';

type MenuServiceProps = Pick<ContextProps, '$w'> & {
  routeDataService: RouteDataService;
};

const SLUG_INDEX = 1;

const getInitialMenuItemsFromMenu = ($w: W) =>
  ($w(ViewerMenuId.Horizontal) as { menuItems?: MenuItem[] })?.menuItems ?? [];

const findRouteByMenuItem = (routes: RouteConfiguration[], item: MenuItem) =>
  routes.find((route) => item?.link?.includes(route.path));

export class MenuService implements IMenuService {
  currentMenuItems: MenuItem[];
  private readonly initialMenuItems: MenuItem[];
  private menuContext!: MenuContext;

  constructor(private readonly contextProps: MenuServiceProps) {
    const initialMenuItems = getInitialMenuItemsFromMenu(contextProps.$w);

    this.currentMenuItems = initialMenuItems;
    this.initialMenuItems = initialMenuItems;
    makeAutoObservable(this);
  }

  initializeMenuItems(menuContext: MenuContext) {
    this.menuContext = menuContext;
    this.filterMenuItems();
    this.fillMenuItemsWithSlugs();
  }

  selectMenuItem(selectedMenuItemLinkIdentifier: string) {
    this.currentMenuItems = this.currentMenuItems.map((menuItem) => {
      const membersAreaPagePath =
        this.getMemberPageRelativePathItemsFromMenuItem(menuItem);

      if (membersAreaPagePath) {
        const routePathIndex = 2;
        const routePath = membersAreaPagePath[routePathIndex];

        return {
          ...menuItem,
          selected: routePath === selectedMenuItemLinkIdentifier,
        };
      }

      return menuItem;
    });
  }

  filterMenuItemsForViewingUser(isViewedMemberCurrentMember: boolean) {
    const { routes } = this.menuContext;

    if (isViewedMemberCurrentMember) {
      return;
    }

    this.currentMenuItems = this.currentMenuItems.filter((item) => {
      const routeConfig = findRouteByMenuItem(routes, item);

      return !routeConfig?.private;
    });
  }

  clearMenuItems() {
    this.currentMenuItems = [];
  }

  restoreMenuItems(menuContext: MenuContext) {
    this.currentMenuItems = this.initialMenuItems;
    this.initializeMenuItems(menuContext);
  }

  private filterMenuItems() {
    const { isViewedMemberCurrentMember } = this.menuContext;

    this.filterMenuItemsForViewingUser(isViewedMemberCurrentMember);
    this.filterMenuItemsVisibleForRoles();
  }

  private filterMenuItemsVisibleForRoles() {
    const { routes } = this.menuContext;
    const { viewedMemberRoles } = this.menuContext;

    this.currentMenuItems = this.currentMenuItems.filter((item) => {
      const routeConfig = findRouteByMenuItem(routes, item);

      if (!routeConfig?.vfr?.length) {
        return true;
      }

      return getIsTabAccessibleWithUserRoles(
        viewedMemberRoles,
        routeConfig.vfr,
      );
    });
  }

  private fillMenuItemsWithSlugs() {
    const { routeDataService } = this.contextProps;
    const { slugOrId } = routeDataService.getRouteData();

    if (!slugOrId) {
      return;
    }

    this.currentMenuItems = this.currentMenuItems.map((item) =>
      this.updateMenuItemWithMemberSlug(item, slugOrId),
    );
  }

  private updateMenuItemWithMemberSlug(item: MenuItem, slugOrId: string) {
    const slugPlaceholder = this.getSlugPlaceholderFromMenuItem(item);

    if (this.isValidSlugPlaceholder(slugPlaceholder)) {
      const replacementSlug = this.getReplacementSlug(slugOrId);

      return this.replaceLinkInMenuItem(item, replacementSlug);
    }

    return item;
  }

  private isValidSlugPlaceholder(slugPlaceholder: string): boolean {
    return SLUG_PLACEHOLDERS.includes(slugPlaceholder);
  }

  private replaceLinkInMenuItem(item: MenuItem, replacementSlug: string) {
    const memberPageRelativePathItems =
      this.getMemberPageRelativePathItemsFromMenuItem(item) ?? [];

    if (memberPageRelativePathItems.length > 0 && item.link) {
      const link = item.link.replace(
        memberPageRelativePathItems.join('/'),
        this.getMemberPageRelativePathWithMemberSlug(
          memberPageRelativePathItems,
          replacementSlug,
        ),
      );

      return { ...item, link };
    }

    return item;
  }

  private getMemberPageRelativePathWithMemberSlug(
    membersAreaPageRelativePathItems: string[],
    replacementSlug: string,
  ) {
    return membersAreaPageRelativePathItems
      .map((item, index) => (index === SLUG_INDEX ? replacementSlug : item))
      .join('/');
  }

  private getReplacementSlug(slugOrId: string) {
    const { currentMember } = this.menuContext.members;

    if (currentMember?.id === slugOrId && currentMember.profile?.slug) {
      return currentMember.profile.slug;
    }

    return slugOrId;
  }

  private getSlugPlaceholderFromMenuItem(item: MenuItem) {
    const defaultSlugPlaceholder = '';
    const membersAreaPageRelativePathItems =
      this.getMemberPageRelativePathItemsFromMenuItem(item);

    return membersAreaPageRelativePathItems
      ? membersAreaPageRelativePathItems[SLUG_INDEX] ?? defaultSlugPlaceholder
      : defaultSlugPlaceholder;
  }

  private getMemberPageRelativePathItemsFromMenuItem(item: MenuItem) {
    if (item.link) {
      const { membersAreaPagePrefix } = this.menuContext;
      const indexOfMembersAreaPagePrefix = item.link.indexOf(
        membersAreaPagePrefix,
      );

      return item.link.slice(indexOfMembersAreaPagePrefix).split('/');
    }

    return null;
  }
}
