import { CurrentUserService, WixCodeApi } from '../../../../types';

export class CurrentUserFromWixCodeService implements CurrentUserService {
  constructor(
    private readonly userAPI: Pick<WixCodeApi['user'], 'currentUser'>,
  ) {}

  getCurrentUserId() {
    return this.userAPI.currentUser.loggedIn
      ? this.userAPI.currentUser.id
      : null;
  }
}
