import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { Experiment } from '../../../constants';
import { getLogEnvironmentTags } from '../services/monitoring';
import {
  ContextProps,
  ContextServices,
  ControllerData,
  InitState,
  Members,
  Nullable,
  RouteConfiguration,
  State,
} from '../../../types';

type GetStateProps = {
  initState: InitState;
  contextServices: ContextServices;
  config: ControllerData;
};

const checkControllersRoutesIntegrity = (
  flowAPI: PlatformControllerFlowAPI,
  globalRoutes: RouteConfiguration[],
  bobRoutes: RouteConfiguration[],
) => {
  if (globalRoutes.length !== bobRoutes.length) {
    flowAPI.errorMonitor.captureMessage(
      'Warning: Difference in length between global and BoB controller routes',
      {
        // @ts-expect-error - missing sentry type
        extra: {
          globalRoutes,
          bobRoutes,
        },
      },
    );
    return;
  }

  const mismatchingRoute = globalRoutes.find((route) => {
    const matchingRoute = bobRoutes.find(
      (bobRoute) => bobRoute.widgetId === route.widgetId,
    );
    return !matchingRoute;
  });

  if (mismatchingRoute) {
    flowAPI.errorMonitor.captureMessage(
      'Warning: Difference in global and BoB controller routes',
      {
        // @ts-expect-error - missing sentry type
        extra: {
          globalRoutes,
          bobRoutes,
        },
      },
    );
  }
};

export const getState = ({
  initState,
  contextServices,
  config,
}: GetStateProps) => {
  const {
    membersService,
    rolesService,
    pageService,
    flowAPI,
    membersAreaPublicAPI,
  } = contextServices;

  const { state } = initState<State>({
    routes: [],
    members: { currentMember: null, viewedMember: null },
    viewedMemberRoles: [],
    isViewedMemberCurrentMember: false,
    membersAreaPagePrefix: '',
    fetchMembers: async (
      currentMemberId: Nullable<string>,
      viewedMemberIdOrSlug: Nullable<string>,
    ) => {
      const members = await membersService.fetchCurrentAndViewedMember(
        currentMemberId,
        viewedMemberIdOrSlug,
      );

      state.members = members;
      state.isViewedMemberCurrentMember =
        membersService.isViewedMemberCurrentMember(
          members.viewedMember?.id!,
          members.currentMember,
        );
    },
    fetchViewedMemberRoles: async () => {
      state.viewedMemberRoles = await rolesService.getMemberRoles(
        state.members.viewedMember?.id ?? null,
      );
    },
    fetchMembersAreaPagePrefix: async () => {
      const membersAreaPagePrefix =
        await pageService.getMembersAreaPagePrefix();
      state.membersAreaPagePrefix = membersAreaPagePrefix.replace('/', '');
    },
    fetchRouteConfigurations: async () => {
      const { isEditor } = flowAPI.environment;
      const useGlobalRoutes = flowAPI.experiments.enabled(
        Experiment.UseRoutesFromGlobalController,
      );

      if (!useGlobalRoutes) {
        state.routes = config.routes ?? [];
        // Causing a lot of noise in sentry - disabling for now
        /* ? config.routes
          : await routesConfigurationService.fetchRouteConfigurations();*/
        return;
      }

      let routes: RouteConfiguration[] | undefined;

      try {
        routes = await membersAreaPublicAPI.getRoutes();

        if (routes?.length && config?.routes?.length) {
          checkControllersRoutesIntegrity(flowAPI, routes, config.routes);
        }

        routes = routes?.length ? routes : config.routes;
      } catch (e: any) {
        const tags = { error: e.toString(), ...getLogEnvironmentTags(flowAPI) };
        if (!isEditor) {
          flowAPI.errorMonitor.captureMessage(
            'Warning: global controller routes are missing, fallbacking to BoB controller routes',
            { tags },
          );
        }
        routes = config.routes;
      }

      if (!isEditor && (!routes || routes.length === 0)) {
        flowAPI.errorMonitor.captureMessage(
          'Error: routes are missing in global and BoB controllers',
          { tags: getLogEnvironmentTags(flowAPI) },
        );
      }

      state.routes = routes || [];
    },
  });

  return state;
};

const getIsCurrentMemberChanged = (
  members: Members,
  currentMemberId: Nullable<string>,
) => {
  return currentMemberId !== members.currentMember?.id;
};

const getIsViewedMemberChanged = (
  members: Members,
  viewedMemberSlugOrId: Nullable<string>,
) => {
  const viewedMemberId = members.viewedMember?.id;
  const viewerMemberSlug = members.viewedMember?.profile?.slug;

  return !(
    viewerMemberSlug === viewedMemberSlugOrId ||
    viewedMemberId === viewedMemberSlugOrId
  );
};

export const fetchViewedAndCurrentMember = async (
  { state, flowAPI }: Pick<ContextProps, 'state' | 'wixCodeApi' | 'flowAPI'>,
  {
    currentUserService,
    routeDataService,
  }: Pick<ContextServices, 'currentUserService' | 'routeDataService'>,
) => {
  const { members, fetchMembers, fetchViewedMemberRoles } = state;

  // Avoiding refetch in the editor - ids always differ in workspaces when using identity
  if (
    flowAPI.environment.isEditor &&
    members.currentMember &&
    members.viewedMember
  ) {
    return { hasMemberChanged: false };
  }

  const { slugOrId } = routeDataService.getRouteData();

  const currentMemberId = currentUserService.getCurrentUserId();
  const isCurrentMemberChanged = getIsCurrentMemberChanged(
    members,
    currentMemberId,
  );
  const isViewedMemberChanged = getIsViewedMemberChanged(members, slugOrId);
  const hasMemberChanged = isViewedMemberChanged || isCurrentMemberChanged;

  if (isCurrentMemberChanged || isViewedMemberChanged) {
    await fetchMembers(currentMemberId, slugOrId);
    await fetchViewedMemberRoles();
  }

  return { hasMemberChanged };
};

export const fetchInitialMemberPageData = async ({
  state,
}: Pick<ContextProps, 'state'>) => {
  const { fetchRouteConfigurations, fetchMembersAreaPagePrefix } = state;
  return Promise.all([
    fetchRouteConfigurations(),
    fetchMembersAreaPagePrefix(),
  ]);
};
