import { IHttpClient } from '@wix/yoshi-flow-editor';
import { queryRouteConfigurations } from '@wix/ambassador-members-routes-v1-route-configuration/http';

import type {
  RouteConfiguration,
  RoutesConfigurationServiceViewer as IRoutesConfigurationServiceViewer,
} from '../../../types';

export class RoutesConfigurationServiceViewer
  implements IRoutesConfigurationServiceViewer
{
  constructor(private httpClient: IHttpClient) {}

  async fetchRouteConfigurations() {
    const response = await this.httpClient.request(
      queryRouteConfigurations({}),
    );

    return (response?.data.routeConfigurations ?? []) as RouteConfiguration[];
  }
}
