import { PageService as IPageService, WixCodeApi } from '../../../types';
import { PROFILE_PAGE_BOB_APP_DEF_ID } from '../../../constants';

export class PageService implements IPageService {
  constructor(
    private readonly siteAPI: Pick<WixCodeApi['site'], 'getSiteStructure'>,
  ) {}

  async getMembersAreaPagePrefix() {
    const { prefixes } = await this.siteAPI.getSiteStructure();
    const membersAreaPagePrefixData = prefixes.find(
      ({ applicationId }) => applicationId === PROFILE_PAGE_BOB_APP_DEF_ID,
    );

    if (!membersAreaPagePrefixData) {
      throw new Error(
        'Error: missing members area page - failed to find prefix',
      );
    }

    return membersAreaPagePrefixData.prefix;
  }
}
