import { getPageIdAndAppDefIdFromWidgetId } from '@wix/members-area-app-definitions';
import { membersAreaNavigation } from '@wix/bi-logger-members-app-uou/v2';

import {
  ContextProps,
  ContextServices,
  FlowAPI,
  MenuItem,
  RouteConfiguration,
} from '../../../types';
import { ViewerMenuId } from '../../../constants';
import {
  fetchInitialMemberPageData,
  fetchViewedAndCurrentMember,
} from './state';
import { addHandlers } from '../handlers';

const MENU_ARIA_LABEL = 'Member Page';

const emitMembersAreaNavigationEvent = (
  flowAPI: FlowAPI,
  routes: RouteConfiguration[],
  menuItem: MenuItem,
) => {
  const routeConfig = routes.find((route) =>
    menuItem?.link?.includes(route.path),
  );

  if (!routeConfig) {
    return;
  }

  const { pageId, appDefId } = getPageIdAndAppDefIdFromWidgetId(
    routeConfig.widgetId,
  );

  flowAPI.bi?.report(
    membersAreaNavigation({
      pageName: pageId,
      action: 'menu-item-click',
      component: 'mav2-navigation',
      originAppId: appDefId,
    }),
  );
};

const bindElements = (
  { bind, flowAPI, state }: ContextProps,
  { menuService }: ContextServices,
) => {
  const accessibility = {
    ariaAttributes: {
      label: () => MENU_ARIA_LABEL,
    },
  };

  bind('#homeButton', {
    link: () => '/',
  });

  bind(ViewerMenuId.Horizontal, {
    // @ts-expect-error: https://www.wix.com/velo/reference/$w/menu
    menuItems: () => menuService.currentMenuItems,
    onItemClick: ({ item }: { item: MenuItem }) => {
      emitMembersAreaNavigationEvent(flowAPI, state.routes, item);
    },
    accessibility,
  });

  bind(ViewerMenuId.Vertical, {
    // @ts-expect-error: https://www.wix.com/velo/reference/$w/menu
    menuItems: () => menuService.currentMenuItems,
    onItemClick: ({ item }: { item: MenuItem }) => {
      emitMembersAreaNavigationEvent(flowAPI, state.routes, item);
    },
    accessibility,
  });
};

const setInitialViewProps = (
  multiStateBoxService: ContextServices['multiStateBoxService'],
) => multiStateBoxService.showMainAppState();

// To do: multiple requests can be async for performance
export const pageReady = async (
  contextProps: ContextProps,
  contextServices: ContextServices,
) => {
  const { widgetPluginHostService, menuService, routeDataService } =
    contextServices;

  widgetPluginHostService.setInitialData();
  await setInitialViewProps(contextServices.multiStateBoxService);

  await fetchInitialMemberPageData(contextProps);
  routeDataService.initializeRouteData(contextProps.state);

  await fetchViewedAndCurrentMember(contextProps, contextServices);
  menuService.initializeMenuItems(contextProps.state);
  bindElements(contextProps, contextServices);

  return addHandlers(contextProps, contextServices);
};

export { fetchViewedAndCurrentMember };
