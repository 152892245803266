import {
  AppParams,
  InstanceService as IInstanceService,
  WixCodeApi,
} from '../../../types';

export class InstanceService implements IInstanceService {
  private instance = this.appParams.instance;

  constructor(
    private appParams: Pick<AppParams, 'appDefinitionId' | 'instance'>,
    private readonly siteAPI: Pick<WixCodeApi['site'], 'onInstanceChanged'>,
  ) {
    this.registerOnInstanceChangeListener();
  }

  getInstance() {
    return this.instance ?? null;
  }

  private registerOnInstanceChangeListener() {
    this.siteAPI.onInstanceChanged((event) => {
      this.instance = event.instance;
    }, this.appParams.appDefinitionId);
  }
}
